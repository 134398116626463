import React from "react";
import { connect } from "react-redux";
import {changeCurency, numberValue} from "../redux/actions/userActions"
import {withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import {translate} from "react-i18next";

const fakeDB = ['EUR','AUD','CAD','CHF','CNH','CZK','DKK','GBP','HKD','JPY',
                'MXN','NOK','NZD','PLN','RUB','SEK','SGD','TRY','USD','ZAR',
                'COP','ALL','MDL','AMD','ANG','GNF','TND','KGS','BOB','MNT','FRF','LAK','NGN','GYD',
                'ITL','BGN','PLX','SDG','XPF','NAD','LRD','SIT','INR','UAH','CRC','GEL','KWD','SHP',
                'HUF','TOP','DEM','ISK','XDR','NLG','AOA','FKP','SRD','SZL','GMD','LKR','HRK','IQD',
                'GRD','BRL','PGK','LUF','HTG','DZD','BND','NIO','BSD','RON','PYG','PKR','MOP','FIM',
                'VUV','MMK','DOP','WST','IDR','TMT','GTQ','UYU','UZS','CLF','BWP','PHP','JOD','XAF',
                'BEF','QAR','GHS','LBP','LSL','BIF','XOF','MUR','LYD','RSD','ERN','AFN','PEN','GBX',
                'AED','TZS','RWF','YER','SBD','CLP','MZN','MKD','ARS','KHR','HNL','GIP','MYR','THB',
                'SCR','UGX','SAR','ESP','ATS','OMR','CVE','KES','PTE','EGP','NPR','KZT','SOS','MGA',
                'KMF','BHD','XCU','IEP','MXV','KRW','BTN','DJF','TTD','BDT','MAD','AWG','MVR','MWK',
                'CNY','ILS','JMD','BAM',

];

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);



class DemoMiniHomePage extends  React.Component{
    constructor(props){
        super(props);
        this.state = {
            activeOption1: null,
            activeOption2: null,
            rate: ""
        }

        this.showMenu1 = this.showMenu1.bind(this);
        this.closeMenu1 = this.closeMenu1.bind(this);
        this.showMenu2 = this.showMenu2.bind(this);
        this.closeMenu2 = this.closeMenu2.bind(this);
    }

    showMenu1(event) {
        event.preventDefault();
        this.setState({activeOption1: this.state.activeOption1 === "select1" ? null : "select1", activeOption2: null}, () => {
            document.addEventListener('click', this.closeMenu1);
        });
    }

    closeMenu1() {
        this.setState({ activeOption1: null }, () => {
            document.removeEventListener('click', this.closeMenu1);
        });
    }

    showMenu2(event) {
        event.preventDefault();
        this.setState({activeOption2: this.state.activeOption2 === "select2" ? null : "select2", activeOption1: null}, () => {
            document.addEventListener('click', this.closeMenu2);
        });
    }

    closeMenu2() {
        this.setState({ activeOption2: null }, () => {
            document.removeEventListener('click', this.closeMenu2);
        });
    }
    componentDidMount(data) {
        const currenciesArrayFromHistory = this.props.match.params.curencies && this.props.match.params.curencies.split("-");
        const currenciesAmount = this.props.match.params.amount;
        if(currenciesAmount){
            this.props.onInitialNumberValue(currenciesAmount)
        }
        if(currenciesArrayFromHistory){
            console.error("jijijij", currenciesAmount);
            this.props.onCurrencyChanged(currenciesArrayFromHistory[0].toUpperCase(), currenciesArrayFromHistory[1].toUpperCase(), true);
        }else {
            this.props.onCurrencyChanged(this.props.cur, this.props.cur2, true);

        }
    }

    render() {
        const {activeOption1, activeOption2} = this.state;
        const {cur, cur2, changedAmount, onCurrencyChanged, rate, number}= this.props;
        const { t } = this.props;

        const myCur_first = `${cur}`;
        const myCur_second = `${cur2}`;
        const myCur_first_plural = `${cur}_PLURAL`;
        const myCur_second_plural = `${cur2}_PLURAL`;
        const myCur_first_dative = `${cur}_DATIVE`;
        const myCur_second_dative = `${cur2}_DATIVE`;
        const myCur_first_multiple = `${cur}_MULTIPLE`;
        const myCur_second_multiple = `${cur2}_MULTIPLE`;
        const myCur_first_trans = `${cur}_TRANS`;
        const myCur_second_trans = `${cur2}_TRANS`;
        const ifc_domain = `$_IFC_DOMAIN`;

        return (
            <div>
               <Helmet>
                {/*{*/}
                {/**/}
                    {/*(cur==="EUR" && cur2==="USD" && (this.props.language === 'en' || this.props.language === 'ru'))*/}
                        {/*?*/}
                        {/*<title>{`${(t('EURUSD',*/}
                            {/*{*/}
                                {/*CUR1: myCur_first,*/}
                                {/*CUR2: myCur_second,*/}
                                {/*CUR1_PLURAL: (t(myCur_first_plural)),*/}
                                {/*CUR2_PLURAL: (t(myCur_second_plural)),*/}
                                {/*CUR1_DATIVE: (t(myCur_first_dative)),*/}
                                {/*CUR2_DATIVE: (t(myCur_second_dative)),*/}
                                {/*CUR1_MULTIPLE: (t(myCur_first_multiple)),*/}
                                {/*CUR2_MULTIPLE: (t(myCur_second_multiple)),*/}
                                {/*CUR1_TRANS: (t(myCur_first)),*/}
                                {/*CUR2_TRANS: (t(myCur_second)),*/}
                                {/*AMOUNT: number*/}
                            {/*}*/}
                        {/*))} | CFDRATES`}*/}
                        {/*</title>*/}
                        {/*:*/}
                        <title>
                            {`${this.props.number} ${this.props.cur} to ${this.props.cur2} | ${this.props.cur} to ${this.props.cur2} Calculator | ${this.props.cur} ${this.props.cur2} Conversion | CFDRATES`}
                        </title>
                {/*}*/}

                <meta name="description" content="Todos on steroid!" />
                <meta name="theme-color" content="#008f68" />
            </Helmet>
            <div className="">
            <div className="currency_box">

                <div className="left_box">
                    <div className="left_text">
                        <h1>{renderHtml(t('$_CURRENCY_CONVERTER', {
                            AMOUNT:number,
                                CUR1: myCur_first,
                                CUR2: myCur_second,
                            CUR1_PLURAL: (t(myCur_first_plural)),
                            CUR2_PLURAL: (t(myCur_second_plural)),
                            CUR1_DATIVE: (t(myCur_first_dative)),
                            CUR2_DATIVE: (t(myCur_second_dative)),
                            CUR1_MULTIPLE: (t(myCur_first_multiple)),
                            CUR2_MULTIPLE: (t(myCur_second_multiple)),
                            CUR1_TRANS: (t(myCur_first_trans)),
                            CUR2_TRANS: (t(myCur_second_trans)),
                        }
                        ))}</h1>
                        <div className="p2">{renderHtml(t('$_Real_TIME_LIVE'))}</div>
                        <div className="select_div"
                             id="select_wrapper"
                             data-cur1="USD"
                             data-cur2="EUR">
                            <div className="sections sec1">
                                <input id="__frst_conve_val"
                                       type="number"
                                       name="amount"
                                       value={number}
                                       className="magicLabels"
                                       onChange={(e) => this.props.onNumberValue(e)}
                                />
                            </div>
                            <div className="sections sec2"
                                 onClick={this.showMenu1}
                                 // onClick={() => this.setState({activeOption1: activeOption1 === "select1" ? null : "select1"})}
                            >
                                <div className="selected" >
                                    <span className={`flag_24 ${cur}`}/>
                                    <span className="currency">{cur}</span>
                                    <span className="arrow_d"/>
                                </div>
                                <div className="dflag"
                                     data-cur="cur1"
                                    style={activeOption1 === "select1"? {display: "block"} : null}

                                >
                                    {
                                        fakeDB.filter(item => item !== cur2.toUpperCase()).map((item, index) => {
                                            return <div className="one_by_one "
                                                        key={index}
                                                        data-valute="aud"
                                                        onClick={() => onCurrencyChanged(item, cur2)}
                                            >
                                                <span className={`flag_24 ${item}`}/>
                                                <span className="currency">{item} </span>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                            <div className="sections sec3"
                                 onClick={()=> onCurrencyChanged(cur2, cur)}>
                                <button id="replasecur" className="center-block converter_replasecur">
                                    <span/>
                                </button>
                            </div>
                            <div className="sections sec4"
                                 onClick={this.showMenu2}>
                                <div className="selected">
                                    <span className={`flag_24 ${cur2}`}/>
                                    <span className="currency">{cur2}</span>
                                    <span className="arrow_d"/>
                                </div>

                                <div className="dflag" data-cur="cur2" style={activeOption2 === "select2"? {display: "block"} : null}>
                                    {
                                        fakeDB.filter(item => item !== cur.toUpperCase()).map((item, index) => {
                                            return <div className="one_by_one "
                                                        key={index}
                                                        data-valute="aud"
                                                        onClick={() => onCurrencyChanged(cur, item)}
                                            >
                                                <span className={`flag_24 ${item}`}/>
                                                <span className="currency">{item} </span>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="select_text" dir="ltr">
                            {
                                (number>0 || number<0)? <span className="amount" id="cur1_val">{number}</span>
                                    :  <span className="amount" id="cur1_val">0</span>
                            }
                            <span className="cur1" data-currency="usd">{` ${cur}`}</span>
                            {` = `}
                            {
                                ((number * (1 * rate)).toFixed(6) > 0)?<span id="fz">
                                <span className="result" id="_calc_wrap">{changedAmount}</span>
                                <span className="cur2" data-currency="eur">{`${(number * (1 * rate)).toFixed(6)} ${cur2}`}</span>
                                </span>
                                    :
                                    `${cur2}`
                            }
                        </div>

                    </div>
                    <div className="p2 p2-currency">
                        <div className="select_text" dir="ltr">
                            {
                                <span className="amount" id="cur1_val">1</span>
                            }
                            <span className="cur1" data-currency="usd">{` ${cur}`}</span>
                            <span className="equal">{` = `}</span>
                            {
                               <span id="fz">
                                <span className="result" id="_calc_wrap">{changedAmount}</span>
                                <span className="cur2 cur2-color" data-currency="eur">{`${((1 * rate)).toFixed(2)} `}</span>
                                   <span className="cur2">{`${cur2}`}</span>
                                </span>
                            }
                            <span className="slash">{` / `}</span>
                            {
                                <span className="amount" id="cur1_val">1</span>
                            }
                            <span className="cur1" data-currency="usd">{` ${cur2}`}</span>
                            <span className="equal">{` = `}</span>
                            {
                               <span id="fz">
                                <span className="result" id="_calc_wrap">{changedAmount}</span>
                                <span className="cur2 cur2-color" data-currency="eur">{`${((1/rate)).toFixed(2)} `}</span>
                                   <span className="cur2" data-currency="eur">{`${cur}`}</span>
                                </span>
                            }
                        </div>
                    </div>

                    {/*// <h2 className="white_h2">*/}
                        {/*{renderHtml(t('$_TITLE_CURR_CONV', {*/}
                            {/*CUR1:(t(myCur_first)),*/}
                            {/*CUR2: (t(myCur_second)),*/}
                            {/*CUR1_PLURAL: (t(myCur_first_plural)),*/}
                            {/*CUR2_PLURAL: (t(myCur_second_plural)),*/}
                            {/*CUR1_DATIVE: (t(myCur_first_dative)),*/}
                            {/*CUR2_DATIVE: (t(myCur_second_dative)),*/}
                            {/*CUR1_MULTIPLE: (t(myCur_first_multiple)),*/}
                            {/*CUR2_MULTIPLE: (t(myCur_second_multiple)),*/}
                            {/*CUR1_TRANS: (t(myCur_first_trans)),*/}
                            {/*CUR2_TRANS: (t(myCur_second_trans)),*/}
                        {/*}))}*/}
                    {/*</h2>*/}
                </div>
                <div className="right_box">
                    {/*<div className="right_text">*/}
                        {/*{renderHtml(t('$_WANT_TO_EARN'))}*/}
                    {/*</div>*/}
                    {/*<div className="right_button">*/}
                            {/*{renderHtml(t('$_OPEN_FOREX',{IFC_DOMAIN: (t(ifc_domain)),}))}*/}
                    {/*</div>*/}
                    {/*<div className="div_text">*/}
                            {/*{renderHtml(t('$_FREE_DEMO_AVAILABLE', {IFC_DOMAIN: (t(ifc_domain)),}))}*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
            </div>);
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        rate: state.rate,
        number: state.number,
        language: state.language
    }),
    dispatch => ({
        onCurrencyChanged: (cur, cur2, isInitial) => dispatch(changeCurency(cur, cur2, isInitial)),
        onNumberValue: (e) => dispatch(numberValue(e.target.value)),
        onInitialNumberValue: (num) => dispatch(numberValue(num))
    })
)(translate('common')(withRouter(DemoMiniHomePage)))

