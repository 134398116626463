import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import Tabs from "../tab_component/Tabs";
import Tab from "../tab_component/Tab";
import CurrenciesInIFCMarkets from "./CurrenciesInIFCMarkets";
import WorldCurrenciesAbbreviations from "./WorldCurrenciesAbbreviations";
import {Helmet} from "react-helmet";
import {translate} from "react-i18next";
import { MAIN_ROUTE_BY_LANGUAGE__FUNC} from "../helpers/constants";
import {connect} from "react-redux";
import DescList from "./DescList";
import GoogleAd from "./Banners/GoogleAd";

const style = {
    marginBottom: '30px'
};

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);



class WorldCurrency extends Component {
    constructor(props){
        super(props);
        this.state= {
            see: "",
            hide: "none",
            slide: "slideNone",
            searchText: "",
        }
    }

    render() {

        const { t, language } = this.props;
        const ifc_domain = `$_IFC_DOMAIN`;


        return (
            <div>
                <Helmet>
                    <title>{`${(t('&_CURRENCY_ABBREVIATIONS'))} | CFDRATES`}</title>
                    <meta name="description" content={(t('&_CURRENCY_ABBREVIATIONS_TXT'))} />
                    <meta name="theme-color" content="#008f68" />
                </Helmet>
                {
                    <GoogleAd
                        client="ca-pub-7838140187159395"
                        slot="5238936620"
                        format="auto"
                        wrapperDivStyle={style}
                    />
                }
                <div className="bradcrumbs size_900">
                    <Link to={MAIN_ROUTE_BY_LANGUAGE__FUNC(language)}>{(t('$_HOME'))}</Link><span className="brdcrumbs_line"> / </span>{(t('$_WORLD_ABBR'))}
                </div>
            <div>
            <div className="currencies size_900">
              <DescList />
                    <div className="head_text">
                        {renderHtml(t('$_CURRENCY_SYMBOLS_LIST'))}
                        <Tabs>
                            <Tab label={renderHtml(t('$_IFC_CURRNECIES'))} >
                                <div>
                                <div className="instrumentsearch">
                                    <span className="result_notfound">
                                        {renderHtml(t('SEARCH_NO_RESULT'))}
                                    </span>
                                    <ul className="instrumentsearch_cont">
                                        <li>
                                            <label htmlFor="">
                                            {renderHtml(t('$_CURRENCIES_SEARCH_LABEL'))}
                                        </label>
                                            <div className="search_div">
                                                <div className="search_icon"/>
                                                <input id="search-highlight"
                                                       name="search-highlight"
                                                       maxLength="10"
                                                       placeholder={(t('$_CURRENCIES_SEARCH_TIP'))}
                                                       type="text"
                                                       data-nodata="Sorry, no results were found"
                                                       onChange={(e)=> {
                                                           this.setState({searchText: e.target.value});
                                                       }
                                                       }
                                                />
                                                </div>
                                            <span className="instrument_search_cont_icon icon-45 ifcm-search">
                                                <span/>
                                            </span>
                                            <div className="swipe">
                                                {renderHtml(t('$_SWIPE'))}
                                                <span/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                              <CurrenciesInIFCMarkets searchText={this.state.searchText}/>
                                </div>
                            </Tab>
                            <Tab label={renderHtml(t('$_CURRENCIES_ABBREVIATIONS'))}>
                                <div>
                                <div className="instrumentsearch">
                                    <span className="result_notfound">
                                         {renderHtml(t('SEARCH_NO_RESULT'))}
                                    </span>
                                    <ul className="instrumentsearch_cont">
                                        <li><label htmlFor="">
                                            {renderHtml(t('$_CURRENCIES_SEARCH_LABEL'))}
                                        </label>
                                            <div className="search_div">
                                                <div className="search_icon"/>
                                                <input id="search-highlight"
                                                       name="search-highlight"
                                                       maxLength="10"
                                                       placeholder={(t('$_CURRENCIES_SEARCH_TIP'))}
                                                       type="text" data-list=".highlight_list"
                                                       autoComplete="off"
                                                       data-nodata="Sorry, no results were found"
                                                       onChange={(e)=> {
                                                           this.setState({searchText: e.target.value});
                                                       }
                                                       }
                                                />
                                                </div>
                                            <span className="instrument_search_cont_icon icon-45 ifcm-search"><span/></span>
                                            <div className="swipe">
                                                {renderHtml(t('$_SWIPE'))}
                                                <span/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                              <WorldCurrenciesAbbreviations searchText={this.state.searchText} />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="want_to_earn">
                    <div className="earn_text">
                        {renderHtml(t('$_OPEN_ACCOUNT_NOW'))}
                    </div>
                    <div className="earn_button">
                        <a href={`${(t(ifc_domain))}/${language}/open-trading-account`} className="button_green btn-md">
                            {renderHtml(t('$_OPEN_TRADING_ACCOUNT'))}
                        </a>
                    </div>
                    <div className="divs_p">
                        {renderHtml(t('$_CURRENCIES_ARE_TRADED'))}
                    </div>
                    <div className="earn_text">
                        {renderHtml(t('$_DAILY_TURNOVER'))}
                    </div>
                    <div className="earn_button">
                        {
                            (this.props.language === "ID")
                                ?
                                <a href={`https://www.ifcmarkets.co.id/forex-trading-education`} className="button_green btn-md">
                                    {renderHtml(t('$_FREE_FOREX_EDUCATION'))}
                                </a>
                                :
                                <a href={`${(t(ifc_domain))}/${language}/forex-trading-education`} className="button_green btn-md">
                                    {renderHtml(t('$_FREE_FOREX_EDUCATION'))}
                                </a>
                        }

                    </div>
                </div>
            </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        language: state.language
    }),
)(withRouter(translate('common')(WorldCurrency)));

