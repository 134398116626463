import React from "react";
import {Link, Route, Switch, withRouter,} from "react-router-dom";
import { translate } from 'react-i18next';

import {openMobileMenu} from "../redux/actions/userActions"
import OtherMini from "../MiniComponents/OtherMini";
import DemoMiniHomePage from "../MiniComponents/DemoMiniHomePage";
import { connect } from "react-redux";
import {
    MAIN_ROUTE,
    CALCULATOR_ROUTE,
    CURRENCY_ROUTE,
    MARGIN_ROUTE,
    PROFIT_LOSS_ROUTE,
    CALCULATOR_ROUTE_MAKER,
    MAIN_ROUTE_BY_LANGUAGE,
    CURRENCY_ROUTE_MAKER,
    MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUT
} from "./../helpers/constants";
import {MAIN_ROUTE_BY_LANGUAGE__FUNC, MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES} from "../helpers/constants";
// import NotFound from "./NotFound";
import {onLanguageChange} from "../redux/actions/userActions";






const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);
const toggleClass = (el, className) => el.classList.toggle(className);


class Header extends React.Component  {
    constructor(props){
        super(props);
        this.state = {
            showMenu: false,
            cur: 0,
            show: "none",
            show2: "none",
            // common: "en",
            data: [
                {
                    name: "United Kingdom",
                    lang: "flag_24 en",
                    href: "https://cfdrates.com/en",
                    common: "en"
                },
                {
                    name: "Russia",
                    lang: "flag_24 ru",
                    href: "https://cfdrates.com/ru",
                    common: "ru"
                },
                {
                    name: "Spain",
                    lang: "flag_24 es",
                    href: "https://cfdrates.com/es",
                    common: "es"
                },
                {
                    name: "Portugal",
                    lang: "flag_24 pt",
                    href: "https://cfdrates.com/pt",
                    common: "pt"
                },
                {
                    name: "Saudi Arabia",
                    lang: "flag_24 ar",
                    href: "https://cfdrates.com/ar",
                    common: "ar"
                },
                {
                    name: "Turkey",
                    lang: "flag_24 tr",
                    href: "https://cfdrates.com/tr",
                    common: "tr"
                },
                {
                    name: "Iran",
                    lang: "flag_24 fa",
                    href: "https://cfdrates.com/fa",
                    common: "fa"
                },
                {
                    name: "Vietnam",
                    lang: "flag_24 vi",
                    href: "https://cfdrates.com/vi",
                    common: "vi"
                },
                {
                    name: "Japan",
                    lang: "flag_24 ja",
                    href: "https://cfdrates.com/ja",
                    common: "ja"
                },
                {
                    name: "Taiwan",
                    lang: "flag_24 zh",
                    href: "https://cfdrates.com/zh",
                    common: "zh"
                },
                {
                    name: "China",
                    lang: "flag_24 zh_cn",
                    href: "https://cfdrates.com/zh_cn",
                    common: "zh_cn"
                },
                {
                    name: "Indonesia",
                    lang: "flag_24 ID",
                    href: "https://cfdrates.com/ID",
                    common: "ID"
                },
                {
                    name: "France",
                    lang: "flag_24 fr",
                    href: "https://cfdrates.com/fr",
                    common: "fr"
                },
                {
                    name: "Czech Republic",
                    lang: "flag_24 cs",
                    href: "https://cfdrates.com/cs",
                    common: "cs"
                },
                {
                    name: "India",
                    lang: "flag_24 hi",
                    href: "https://cfdrates.com/hi",
                    common: "hi"
                },
                {
                    name: "Italy",
                    lang: "flag_24 it",
                    href: "https://cfdrates.com/it",
                    common: "it"
                },
                {
                    name: "Malaysia",
                    lang: "flag_24 ms",
                    href: "https://cfdrates.com/ms",
                    common: "ms"
                }
            ]

        };
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }


    showMenu(event) {
        event.preventDefault();
        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu() {
        this.setState({ showMenu: false }, () => {
            document.removeEventListener('click', this.closeMenu);
        });
    }

    hydrateStateWithLocalStorage() {
        // for all items in state
        for (let key in this.state) {
            // if the key exists in localStorage
            if (localStorage.hasOwnProperty(key)) {
                // get the key's value from localStorage
                let value = localStorage.getItem(key);
                // parse the localStorage string and setState
                try {
                    value = JSON.parse(value);
                    this.setState({ [key]: value });
                } catch (e) {
                    // handle empty string
                    this.setState({ [key]: value });
                }
            }
        }
    }

    componentDidMount() {
        this.hydrateStateWithLocalStorage();
        if(this.props.location.pathname !== "/"){
            const pathArray = this.props.location.pathname.split("/");
            const language = pathArray[1];
            if (language !== this.props.language){
                this.props.i18n.changeLanguage(language);
                document.body.dir = `${(language === 'ar' || language === 'fa') ? 'rtl' : "ltr"}` ;
                this.setState({cur: 0, common: language});
                // localStorage.setItem (index, index);
                localStorage.setItem("cur", JSON.stringify(0));
                localStorage.setItem("language", language)
                this.props.onLanguageChange(language)
        }
      }
    }

   getOpenNewTabPathName = () => {
        const {pathname} = this.props.location;
        if(pathname.length <= 5){
            return ""
        }
        let newPathName = "";
        pathname.split("/").map((path, i) => {
            if(i>2) newPathName += "/";
            if(i === 1) {
                // newPathName += common
            } else {
                newPathName +=path;
            }
        });

        return newPathName;
    }


    render(){

        const { t, i18n, language, location: {pathname}} = this.props;

        return (
            <header>
                <div className="navbar navbar-default site-header">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <Link to={MAIN_ROUTE_BY_LANGUAGE__FUNC(this.props.language)} className="logo-ifc">
                           <div className="cfd-logo"/>
                                <div className="cfd-logo-mobile"/>

                            </Link>
                        </div>
                        <ul className="nav navbar-nav pull-right">
                            <li className={(pathname==="/")?"active":""}>
                                <Link to={MAIN_ROUTE}>
                                    <div dangerouslySetInnerHTML={{__html: t('CURRENCY_CONVERTER')}}/>
                                </Link>
                            </li>
                            <li className={(pathname===`/${language}/forex-calculator`)?"active":""}>
                                <Link to={CALCULATOR_ROUTE_MAKER(language)}>
                                    {renderHtml(t('$_TRADING_CALCULATORS'))}
                                </Link>
                            </li>
                            <li className={(pathname==="/en/currencies-and-abbreviations")?"active":""}>
                                <Link to={CURRENCY_ROUTE_MAKER(language)}>
                                    {renderHtml(t('SECTOR_CURRENCIES'))}
                                </Link>
                            </li>
                            <li className="flag" onClick={this.showMenu} >
                                <span className={`flag_24 ${this.props.language}`}/>
                                <span className="flag_text">{localStorage.getItem("language", ) || "en"}</span>
                                <span  className="flag_arrow"/>

                                {
                                    this.state.showMenu ? (
                                        <div className="flag_none" style={{display: "block"}} >
                                            {
                                                this.state.data.map((item,index)=>{
                                                 return   <div className=" " key={index}>
                                                        <a href={`${item.href}/${this.getOpenNewTabPathName(item.common)}`}
                                                           className="local_href"
                                                           onClick={() => {
                                                               console.error("PATHNAME", this.props.location.pathname.split("/")[2]);
                                                               this.props.onLanguageChange(item.common);
                                                               const pathsArray = pathname.split("/");
                                                               let newPathName = "";
                                                               pathsArray.forEach((e, i) => {
                                                                   newPathName += "/";
                                                                   if(i === 1){
                                                                       newPathName += item.common
                                                                   } else {
                                                                       newPathName += e;
                                                                   }
                                                               });
                                                               i18n.changeLanguage(item.common);
                                                               document.body.dir = `${(item.common === 'ar' || item.common === 'fa') ?'rtl' : "ltr"}` ;
                                                               this.setState({cur: index, common: item.common});
                                                               // localStorage.setItem (index, index);
                                                               localStorage.setItem("cur", JSON.stringify(index));
                                                               localStorage.setItem("language",item.common);
                                                               // localStorage.setItem("common", JSON.stringify(index));
                                                           }
                                                           }>
                                                            <span className={item.lang}></span>
                                                            {item.name}
                                                        </a>
                                                    </div>
                                                })
                                            }
                                            </div>
                                    ):null
                                }

                            </li>


                            <li>
                                <span className={`mobile_menu `}
                                      onClick={() => {this.props.openMobileMenu();
                                      toggleClass(document.body, 'bodyOverflow')} }/>
                            </li>
                        </ul>
                    </div>
                    <div className="cutout"/>
                    <div className="clear"/>
                    <div>
                        <Switch>
                            <Route path={MAIN_ROUTE} exact component={DemoMiniHomePage} />
                            <Route  path={MAIN_ROUTE_BY_LANGUAGE} exact component={DemoMiniHomePage} />
                            <Route  path={MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES} exact component={() => {
                                if(this.props.location.pathname.includes("forex") || this.props.location.pathname.includes("currencies")){
                                    return <OtherMini/>
                                }else {
                                    return <DemoMiniHomePage/>
                                }
                            }} />
                            <Route  path={MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUT} exact component={() => {
                                if(this.props.location.pathname.includes("forex") || this.props.location.pathname.includes("currencies")){
                                    return <OtherMini/>
                                }else {
                                    return <DemoMiniHomePage/>
                                }
                            }} />
                            <Route exact path={CALCULATOR_ROUTE} component={OtherMini} />
                            <Route exact path={CURRENCY_ROUTE} component={OtherMini} />
                            <Route exact path={PROFIT_LOSS_ROUTE} component={OtherMini} />
                            <Route path={MARGIN_ROUTE} component={OtherMini} />
                        </Switch>
                    </div>
                </div>
            </header>
        )
    }
}
export default connect(
    state => ({
        language: state.language
    }),
    dispatch => ({
        openMobileMenu: () => dispatch(openMobileMenu()),
        onLanguageChange: (language) => dispatch(onLanguageChange(language))
    })
)(translate('common')(withRouter(Header)))
