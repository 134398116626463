import React from "react";
import {withRouter, Link} from "react-router-dom";
import {
    MAIN_ROUTE_BY_LANGUAGE__FUNC,
    MARGIN_ROUTE_MAKER,
    PROFIT_LOSS_ROUTE_MAKER
} from "../helpers/constants";
import {Helmet} from "react-helmet";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {onChangePlatformType} from "../redux/actions/userActions";
import GoogleAd from "./Banners/GoogleAd";

const style = {
    marginBottom: '30px'
};


const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

class TradingCulcPage extends React.Component  {
    constructor(props) {
        super(props);
        this.state={
            traderType: "",
            platform: "nttx"
        }
    }
    myFirstHelperFunction(e) {
        const a = e.target.tagName;
        if(a === "A"){
            this.props.history.push(`/${this.props.language}/forex-calculator/profitloss-calculation`)
        }
    }
    mySecondHelperFunction(e) {
        const a = e.target.tagName;
        if(a === "A"){
            this.props.history.push(`/${this.props.language}/forex-calculator/margin-calculation`)
        }
    }

    render() {
        const { t, language, platform } = this.props;

        // console.error("platform------------------------------------", platform);


        return (
            <div>
                <div>

                    <Helmet>
                        <title>{`${(t('$_FOREX_CALCULATOR'))} | CFDRATES`}</title>
                        <meta name="description" content={(t('$_FOREX_CALCULATOR_TXT'))} />
                        <meta name="theme-color" content="#008f68" />
                    </Helmet>

                    {
                        <GoogleAd
                            client="ca-pub-7838140187159395"
                            slot="5238936620"
                            format="auto"
                            wrapperDivStyle={style}
                        />
                    }
                    <div className="bradcrumbs size_900">
                        <Link to={MAIN_ROUTE_BY_LANGUAGE__FUNC(language)}>{(t('$_HOME'))}</Link><span className="brdcrumbs_line"> / </span>{(t('$_TRADING_TOOLS'))}
                    </div>


                    <div className="forex-calc size_900">
                        <h2>{(t('$_TRADING_TOOLS'))}</h2>
                        <div onClick={(e) => this.myFirstHelperFunction(e)}>
                            {renderHtml(t('$_NEW_GO_PROFITLOSS'))}

                        </div>
                        <div className="platf-cont">
                            <Link to={PROFIT_LOSS_ROUTE_MAKER(language)} className="go_to_p_m"
                                  onClick={()=>{this.props.onPlatformChange("nttx")}}
                            >
                                <div className="platf-block">
                                    <span className="platf-icn"/>
                                    <span className="imposible"/>
                                    <span className="platf-icn-2 platf-ntx"/>
                                </div>
                            </Link>
                            <Link to={PROFIT_LOSS_ROUTE_MAKER(language)} className="go_to_p_m"
                                  onClick={()=>{this.props.onPlatformChange("mt4")}}
                            >
                                <div className="platf-block">
                                    <span className="platf-icn"/>
                                    <span className="imposible"/>
                                    <span className="platf-icn-2 mt4-mt5"/>
                                </div>
                            </Link>
                        </div>

                        <Link to={MARGIN_ROUTE_MAKER(language)} className="link-green">
                        </Link>
                        <div onClick={(e) => this.mySecondHelperFunction(e)}>
                            {renderHtml(t('$_NEW_GO_MARGIN'))}
                        </div>
                        <div className="platf-cont">
                            <Link to={MARGIN_ROUTE_MAKER(language)} className="go_to_p_m" onClick={()=>{this.props.onPlatformChange("nttx")}}>
                                <div className="platf-block">
                                    <span className="platf-icn"/>
                                    <span className="imposible"/>
                                    <span className="platf-icn-2 platf-ntx"/>
                                </div>
                            </Link>
                            <Link to={MARGIN_ROUTE_MAKER(language)} className="go_to_p_m" onClick={()=>{this.props.onPlatformChange("mt4")}}>
                                <div className="platf-block">
                                    <span className="platf-icn"/>
                                    <span className="imposible"/>
                                    <span className="platf-icn-2 mt4-mt5"/>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(
    state => ({
        language: state.language,
        platform: state.platform,
    }),
    dispatch => ({
        onPlatformChange: (platform) => dispatch(onChangePlatformType(platform)),
    })
)(withRouter(translate('common')(TradingCulcPage)));