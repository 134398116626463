import React from 'react';
import {translate} from "react-i18next";
// import RegisterForm from "./RegisterForm";
import RegisterForm2 from "./RegisterForm2";
import HomePage from "./HomePage";

class DashBoard extends React.Component{


    render(){

        return(
            <div>
                {/*<RegisterForm />*/}
                <RegisterForm2 />
                <HomePage/>
            </div>
        )
    }
}
export default  (translate('common')(DashBoard));