import React, {Component} from "react";
import {webs} from "./../Socket";
import {connect} from "react-redux";
import {translate} from 'react-i18next';
import {changeCurency, changeNumber} from "../redux/actions/userActions";
import {withRouter} from "react-router-dom";

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);
const number = [1, 10, 25, 50, 100, 150, 200, 250, 500, 1000, 5000, 10000, 50000];


class ExchangeRatetoday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auxiliary: ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR"],
            rate: "",
            invert: "",
        };
        this.scrolling = this.scrolling.bind(this);
    };


    scrolling() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    componentDidMount(data) {
        const newReq = {
            "cmd": "exchange",
            "from": this.props.cur,
            "to": this.props.cur2,
            "auxiliary": ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR"]
        };
        const web_sock = webs('wss://ifccd.net:2053/');
        web_sock.send(newReq, (data) => this.setState({auxiliary: data.auxiliary, rate: data.rate}));
    };

    render() {
        const a = this.state.auxiliary;
        const {t, cur, cur2, language} = this.props;
        const ifc_domain = `$_IFC_DOMAIN`;

        const myCur_first = `${cur}`;
        const myCur_second = `${cur2}`;
        const myCur_first_plural = `${cur}_PLURAL`;
        const myCur_second_plural = `${cur2}_PLURAL`;
        const myCur_first_dative = `${cur}_DATIVE`;
        const myCur_second_dative = `${cur2}_DATIVE`;
        const myCur_first_multiple = `${cur}_MULTIPLE`;
        const myCur_second_multiple = `${cur2}_MULTIPLE`;


        return (
            <div className="rates_table">
                <div className="rates_today">
                    <div className="left_rate">
                        <h2>
                            {renderHtml(t('$_EXCHANGE_RATE_TODAY', {
                                CUR1: myCur_first,
                                CUR2: myCur_second,
                                CUR1_PLURAL: (t(myCur_first_plural)),
                                CUR2_PLURAL: (t(myCur_second_plural)),
                                CUR1_DATIVE: (t(myCur_first_dative)),
                                CUR2_DATIVE: (t(myCur_second_dative)),
                                CUR1_MULTIPLE: (t(myCur_first_multiple)),
                                CUR2_MULTIPLE: (t(myCur_second_multiple)),
                                CUR1_TRANS: (t(myCur_first)),
                                CUR2_TRANS: (t(myCur_second)),
                            }))}
                        </h2>
                        <div className="fulllist">
                            {
                                a.map((item, index) => {
                                    return <div className="all_rates" key={index}>
                                        <div className="exchange-rate"
                                             onClick={() => {
                                                 this.props.onCurrencyChanged(this.props.cur, item.cur);
                                                 this.scrolling();
                                                 this.props.history.push(`/${language}/${cur.toLowerCase()}-${item.cur.toLowerCase()}`)
                                                 // console.log(`/${language}/${cur.toLowerCase()}-${item.cur.toLowerCase()}`)
                                             }
                                             }>
                                            <span className={`flag_24 ${cur}`}/>
                                            <span>1</span>
                                            <span className="__CAD __rate">{cur}</span>


                                        </div>
                                        <span className="equal-symbol">{` = `}</span>
                                        <div className="exchange-rate-right">
                                            <span className={`flag_24 ${item.cur || item}`}/>
                                            {
                                                (cur===item.cur)?<span className="__CAD __rate">1</span>: <span className="__CAD __rate">
                                        {item.rate || "---"}
                                    </span>
                                            }

                                            <span>{item.cur || item}</span>

                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        number: state.number,
        language: state.language
    }),
    dispatch => ({
        onCurrencyChanged: (cur, cur2) => dispatch(changeCurency(cur, cur2)),
        onNumberChanged: (item) => dispatch(changeNumber(item)),
    })
)(translate('common')(withRouter(ExchangeRatetoday)))