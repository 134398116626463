import React from 'react';
import {webs} from "../Socket";
import {connect} from "react-redux";

 class CurrencyInstrument extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            bid: "---",
            ask: "---"
        }
    }

    componentDidMount() {
        const web_sock = webs('wss://ifccd.net:2053/');
        const currentRate = {
            "cmd": "quote",
            "symbol": this.props.item.name
        };
        web_sock.send(currentRate, (data) => this.setState({bid: data.ask, ask: data.bid}));
    }


    render(){
        const {item, index, language} = this.props;
        return (

            <div className="di_content_wrap" key={index}>
                <div className="flagwrap">
                    <span className={`el1 flag_24 ${item.base_symbol}`}/>
                    <span className={`el2 flag_24 ${item.pips_symbol}`}/>
                </div>
                <div className="infowrap">
                    <a href={`/${language}/${item.base_symbol.toLowerCase()}-${item.pips_symbol.toLowerCase()}`}>{item.base_symbol} {item.pips_symbol} </a>
                </div>
                <div className="buttons_cur">
                    <a href={`https://private.ifcmarkets.com/${language}/register`}
                       target="_blank"
                       rel="noopener noreferrer"
                       id="openaccountclick_sell"
                       className="button_dark_red">
                        <span className="quotetag __bid">{this.state.bid}</span>
                    </a>
                    <a href={`https://private.ifcmarkets.com/${language}/register`}
                       target="_blank"
                       rel="noopener noreferrer"
                       id="openaccountclick_buy"
                       className="button_dark_green">
                        <span className="quotetag __ask">{this.state.ask}</span>
                    </a>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        language: state.language,
    }))(CurrencyInstrument);