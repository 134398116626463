import {
    CHANGE_CURRENCY,
    // GET_RATE,
    GET_EXCHANGE,
    getRateSuccess,
    getSuccessExchange,
    GET_AVAILABLE_COUPLES,
    LANGUAGE_CHANGE,
    getAvailableCouplesSuccess
} from "./actions/userActions";
import {webs} from "../Socket";
import {history} from "./../App"

import API from "../api"

const WEB_SOCKET = webs('wss://ifccd.net:2053/');

const middleWare = store => next => {
    return action => {
        const {type, payload} = action;
        switch (type) {
            case CHANGE_CURRENCY : {
                // web_sock.close(Req, (data) => next(getRateSuccess(data.rate)));
                const Req = {
                    "cmd": "exchange",
                    "from": payload.cur,
                    "to": payload.cur2,
                    "auxiliary": ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                        "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR"]
                };

                const pathname = history.location.pathname;
                const state = store.getState();
                const changedPathname = `/${state.language}/${payload.cur.toLowerCase()}-${payload.cur2.toLowerCase()}`;

                if(pathname !== changedPathname && !payload.isInitial) {
                    history.push(`/${state.language}/${payload.cur.toLowerCase()}-${payload.cur2.toLowerCase()}`)
                }

                WEB_SOCKET.send(Req, (data) => next(getRateSuccess(data.rate)));
                next(action);
                break;
            }

            case GET_EXCHANGE : {
                const req = {
                    "cmd": "quote",
                    "symbol": store.getState().availableCouple && store.getState().availableCouple.toUpperCase()
                };

                WEB_SOCKET.send(req, (data) =>{
                    const state = store.getState()
                    const rate = state.cur + state.cur2;
                    const rateReverted = state.cur2 + state.cur;
                    if(data){
                        if(data.symbol === rate || data.symbol === rateReverted){
                            next(getSuccessExchange(data));
                        }
                    }

                });
                break;
            }

            case GET_AVAILABLE_COUPLES: {
                API.getAvailableCurrenciesCouples()
                    .then(availableCouples => next(getAvailableCouplesSuccess(availableCouples.map(e => e.name))))
                break;
            }

            case LANGUAGE_CHANGE: {
                const pathname = history.location.pathname;
                // alert(payload.language)
                const state = store.getState();
                let routeChanged = false;

                if(pathname.includes("currencies-and-abbreviations")){
                         routeChanged= true;
                         history.push(`/${payload.language}/currencies-and-abbreviations`)
                     } else if (pathname.includes("forex-calculator/profitloss-calculation")) {
                    routeChanged= true;
                    history.push(`/${payload.language}/forex-calculator/profitloss-calculation`)
                         }
                else if(pathname.includes("forex-calculator")){
                    routeChanged= true;
                    history.push(`/${payload.language}/forex-calculator`)
                             }
                else if (pathname.includes("forex-calculator/margin-calculation")) {
                    routeChanged= true;
                    history.push(`/${payload.language}/forex-calculator/margin-calculation`)
                             }

                if (!routeChanged){
                    history.push(`/${payload.language}/${state.cur.toLowerCase()}-${state.cur2.toLowerCase()}`)
                }

                    next(action)
                return
            }

            default:
                next(action)
        }
    };
};

export default middleWare;
