import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_en from "./translations/en/common";
import common_ar from "./translations/ar/common";
import common_ru from "./translations/ru/common";
import common_es from "./translations/es/common";
import common_cs from "./translations/cs/common";
import common_fa from "./translations/fa/common";
import common_fr from "./translations/fr/common";
import common_hi from "./translations/hi/common";
import common_ID from "./translations/ID/common";
import common_it from "./translations/it/common";
import common_ja from "./translations/ja/common";
import common_ms from "./translations/ms/common";
import common_pt from "./translations/pt/common";
import common_tr from "./translations/tr/common";
import common_vi from "./translations/vi/common";
import common_zh from "./translations/zh/common";
import common_zh_cn from "./translations/zh_cn/common";


i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    fallbackLng: localStorage.getItem("language", ) || "en",
    debug: true,
    defaultNS: 'common', // this namespace will be used if no namespace shared via context
    fallbackNS: 'common',
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        ru: {
            common: common_ru
        },
        es: {
            common: common_es
        },
        cs: {
            common: common_cs
        },
        fa: {
            common: common_fa
        },
        fr: {
            common: common_fr
        },
        hi: {
            common: common_hi
        },
        ID: {
            common: common_ID
        },
        it: {
            common: common_it
        },
        ja: {
            common: common_ja
        },
        ms: {
            common: common_ms
        },
        pt: {
            common: common_pt
        },
        tr: {
            common: common_tr
        },
        vi: {
            common: common_vi
        },
        zh: {
            common: common_zh
        },
        zh_cn: {
            common: common_zh_cn
        },
        ar: {
            common: common_ar
        },
    },
});


ReactDOM.render(
    <I18nextProvider i18n={i18next}>
          <App />
    </I18nextProvider>, document.getElementById('root'));

serviceWorker.unregister();
