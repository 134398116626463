const API = 'https://www.ifcm.co.uk:2083/instrument';

export default class cfdApi {
    constructor(env){
        this.env = env || "development";
    }


    static getAvailableCurrenciesCouples(){
       return fetch(API+ "?group=forex")
            .then(response => response.json())
    }

}
