import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import HomePage from "./components/HomePage";
import MainLayout from "./layouts/MainLayout";
import WorldCurrency from "./components/WorldCurrency";
import TradingCulcPage from "./components/TradingCulcPage";
import MarginCalculator from "./components/MarginCalculator";
import ProfitLossCalculator from "./components/ProfitLossCalculator";
import NotFound from "./components/NotFound";
import {
    MAIN_ROUTE,
    CALCULATOR_ROUTE,
    CURRENCY_ROUTE,
    MARGIN_ROUTE,
    PROFIT_LOSS_ROUTE,
    MAIN_ROUTE_BY_LANGUAGE,
    MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES,
    MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUT, MAIN_ROUTE_BY_LANGUAGE__FUNC
} from "./helpers/constants";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {getAvailableCouples, getExchange} from "./redux/actions/userActions";
import DashBoard from "./components/DashBoard";

const mg_category_select = [
    {
        value: "forex",
        name: "Currency Pairs"
    },
    {
        value: "indices",
        name: "Indices"
    },
    {
        value: "commodities",
        name: "Commodities"
    },
    {
        value: "stocks",
        name: "Stocks"
    },
    {
        value: "precious-metals",
        name: "Precious Metals"
    },
    {
        value: "gold-trading",
        name: "Gold Instruments"
    },
    {
        value: "personal-instrument-pci",
        name: "PCI Library"
    },
    {
        value: "cfd-etf",
        name: "ETF"
    }
];
const currensys = [
    "AUDCAD","AUDCHF","AUDJPY","AUDNZD","AUDUSD","CADCHF","CADJPY","CHFJPY","EURAUD","EURCAD","EURCHF",
    "EURCZK","EURGBP","EURHKD","EURJPY","EURMXN","EURNZD","EURPLN","EURRUB","EURSEK","EURTRY","EURUSD",
    "EURZAR","GBPAUD","GBPCAD","GBPCHF","GBPJPY","GBPNZD","GBPSEK","GBPUSD","NZDCAD","NZDCHF","NZDJPY",
    "NZDUSD","USDCAD","USDCHF","USDCNH","USDCZK","USDDKK","USDHKD","USDJPY","USDMXN","USDNOK","USDPLN",
    "USDRUB","USDSEK","USDSGD","USDTRY","USDZAR"
];

class Routes extends React.Component{


    render(){

        return (
            <MainLayout>
                <Switch>
                    <Route exact path={MAIN_ROUTE} component={DashBoard} />
                    <Route exact path={MAIN_ROUTE_BY_LANGUAGE} component={DashBoard} />
                    <Route exact path={CALCULATOR_ROUTE} component={TradingCulcPage} />
                    <Route  path={MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES} exact component={() => {
                        if(this.props.location.pathname.includes("forex") || this.props.location.pathname.includes("currencies")){
                            return <WorldCurrency/>
                        }else {
                            return <DashBoard/>
                        }
                    }} />
                    <Route exact  path={MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUT} exact component={() => {
                        if(this.props.location.pathname.includes("forex-calculator/profitloss-calculation")){
                            return <ProfitLossCalculator/>
                        } else  if(this.props.location.pathname.includes("forex-calculator/margin-calculation")){
                                return <MarginCalculator/>
                            } else return <DashBoard/>
                        }
                    } />


                    <Route exact path={CURRENCY_ROUTE} component={WorldCurrency} />
                    <Route exact path={PROFIT_LOSS_ROUTE}
                           component={() => <ProfitLossCalculator mg_category_select={mg_category_select}
                                                                  currensys={currensys} />}
                    />
                    <Route exact path={MARGIN_ROUTE}
                           component={() => <MarginCalculator mg_category_select={mg_category_select}
                                                              currensys={currensys} />}
                    />
                    <Route component={() => <NotFound/>} />
                </Switch>
            </MainLayout>);
    }
}
export default connect(
    state => ({
        language: state.language

    })
)(translate('common')(withRouter(Routes)))
