import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {webs} from "../Socket";
import { connect } from "react-redux";
import {otherCurrancy} from "../redux/actions/userActions";
import {translate} from "react-i18next";

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

class TopLossLeader extends Component {
    constructor(props){
        super(props);
        this.state = {
            up: [],
            down: [],
        };
        this.scrolling = this.scrolling.bind(this);
    }
    scrolling(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    componentDidMount(data) {
        const newReq ={cmd: "gainlose", lang: "en", category: 1};

        const web_sock = webs('wss://ifccd.net:2053/');
        web_sock.send(newReq, (data) => this.setState({ up: data.up, down: data.down, }));
    }

    render() {

        const { t, language } = this.props;

        return (
            <div>
                <h4>{renderHtml(t('$_FINANCIAL_MARKET_TODAY'))}</h4>
                <div className="losers_leaders lead">
                    <h5> {renderHtml(t('$_LEADERS_PER_DAY'))}</h5>
                    <div className="data-leaders-cont __top_gainers">
                        {
                            (this.state.up.length)?
                                this.state.up.slice(6).reverse().map((item,index) => {
                                return   <div className="one_top_los" key={index}>
                                    <div className="top_type">
                                        <div className="cur__name">
                                            <div
                                                  onClick={()=>{
                                                      this.props.otherCurrancy(item.symbol.slice(0,3), item.symbol.slice(3,6));
                                                      this.props.history.push(`/${language}/${item.symbol.slice(0,3).toLowerCase()}-${item.symbol.slice(3,6).toLowerCase()} `)
                                                      this.scrolling();
                                                  }}>
                                                {item.symbol}
                                            </div>
                                        </div>
                                        <div className="cur__type">{item.sub_group_name}</div>
                                    </div>
                                    <div className="bottom_type">
                                        <div className="flag_type">
                                            <span className={`flag_24 ${item.volume_currency}`}/>
                                            <span className={`flag_24 ${item.pipscurrency}`}/>
                                        </div>
                                        <div className="cur_rate">
                                            <span className="arr_rate to_up_sp"/>
                                            {item.percent}%
                                        </div>
                                    </div>
                                </div>
                            })
                                :
                                [1,2,3,4].map((item,index) => {
                                    return   <div className="one_top_los" key={index}>
                                        <div className="bottom_type">
                                            {renderHtml(t('NO_DATA_AVAILABLE'))}
                                        </div>
                                    </div>
                        } )
                        }
                    </div>
                </div>

                <div className="losers_leaders los">
                    <h5>{renderHtml(t('$_LOSERS_PER_DAY'))}</h5>
                    <div className="data-leaders-cont data-leaders-cont-red __top_losers">
                        {
                            (this.state.down.length)
                                ?
                                    this.state.down.slice(0, 4).map((item, index) => {
                                        return <div className="one_top_los" key={index}>
                                            <div className="top_type">
                                                <div className="cur__name">
                                                    <div
                                                        onClick={() => {
                                                            this.props.otherCurrancy(item.symbol.slice(0, 3), item.symbol.slice(3, 6));
                                                            this.props.history.push(`/${language}/${item.symbol.slice(0, 3).toLowerCase()}-${item.symbol.slice(3, 6).toLowerCase()}`)
                                                            this.scrolling();
                                                        }}>
                                                        {item.symbol}
                                                    </div>
                                                </div>
                                                <div className="cur__type">{item.sub_group_name}</div>
                                            </div>
                                            <div className="bottom_type">
                                                <div className="flag_type">
                                                    <span className={`flag_24 ${item.volume_currency}`}/>
                                                    <span className={`flag_24 ${item.pipscurrency}`}/>
                                                </div>
                                                <div className="cur_rate">
                                                    <span className="arr_rate to_down_sp"/>
                                                    {item.percent}%
                                                </div>
                                            </div>
                                        </div>
                                    })
                                :
                                [1, 2, 3, 4].map((item, index) => {
                                    return <div className="one_top_los" key={index}>
                                        <div className="bottom_type">
                                            {renderHtml(t('NO_DATA_AVAILABLE'))}
                                        </div>
                                    </div>
                                })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        language: state.language
    }),
    dispatch => ({
        otherCurrancy: (cur, cur2) => dispatch(otherCurrancy(cur, cur2))
    })
)(translate('common')(withRouter(TopLossLeader)));
