export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const GET_RATE = "GET_RATE";
export const GET_RATE_SUCCESS = "GET_RATE_SUCCESS";
export const GET_EXCHANGE = "GET_EXCHANGE";
export const GET_EXCHANGE_SUCCESS = "GET_EXCHANGE_SUCCESS";
export const OTHER_CURRENCY = "OTHER_CURRENCY";
export const CHANGE_NUMBER = "CHANGE_NUMBER";
export const LANGUAGE_CHANGE = "LANGUAGE_CHANGE";

export const NUMBER_VALUE = "NUMBER_VALUE";
export const GET_AVAILABLE_COUPLES = "GET_AVAILABLE_COUPLES";
export const GET_AVAILABLE_COUPLES_SUCCESS = "GET_AVAILABLE_COUPLES_SUCCESS";

/** Mobile menu **/
export const OPEN_MOBILE_MENU = "OPEN_MOBILE_MENU";
export const CLOSE_MOBILE_MENU = "CLOSE_MOBILE_MENU";
export const CHANGE_PLATFORM_TYPE = "PLATFORM_TYPE";
// export const NUMBER_NULL = "NUMBER_NULL";

export const changeCurency = (cur,cur2, isInitial) => ({type: CHANGE_CURRENCY, payload: {cur,cur2, isInitial}});
export const changeNumber = (item) => ({type: CHANGE_NUMBER, payload: {item}});
export const onLanguageChange = (language) => ({type: LANGUAGE_CHANGE, payload: {language}});
export const onChangePlatformType = (platform) => ({type: CHANGE_PLATFORM_TYPE, payload: {platform}});


export const getRate = (cur, cur2) => ({type: GET_RATE, payload: {cur, cur2}});
export const getRateSuccess = (rate) => ({type: GET_RATE_SUCCESS, payload: {rate}});
export const getExchange = (cur,cur2) => ({type: GET_EXCHANGE, payload: {cur,cur2}});
export const getSuccessExchange = (data) => ({type: GET_EXCHANGE_SUCCESS, payload: {data}});
export const otherCurrancy = (cur,cur2) => ({type: OTHER_CURRENCY, payload: {cur, cur2}});
export const numberValue = (number) => ({type: NUMBER_VALUE, payload: {number}});
// export const numberNull = () => ({type: NUMBER_NULL, payload: 1});

export const getAvailableCouples = () => ({type: GET_AVAILABLE_COUPLES });
// export const handleStyleChange = () => ({type: HANDLE_STYLE_CHANGE });
export const getAvailableCouplesSuccess = (availableCouples) => ({type: GET_AVAILABLE_COUPLES_SUCCESS, payload: {availableCouples} });


export const openMobileMenu = () => ({type: OPEN_MOBILE_MENU });
export const closeMobileMenu = () => ({type: CLOSE_MOBILE_MENU});

