import {
    CHANGE_CURRENCY,
    GET_RATE_SUCCESS,
    GET_EXCHANGE_SUCCESS,
    OTHER_CURRENCY,
    CHANGE_NUMBER,
    LANGUAGE_CHANGE,
    NUMBER_VALUE,
    GET_AVAILABLE_COUPLES_SUCCESS,
    // HANDLE_STYLE_CHANGE,
    // HANDLE_STYLE_CHANGE_CLOSE,
    CLOSE_MOBILE_MENU,
    OPEN_MOBILE_MENU,
    CHANGE_PLATFORM_TYPE
} from "../actions/userActions";

const initialState = {
    cur: "EUR",
    cur2: "USD",
    availableCouple: "eurusd",
    availableCouples: [
        "AUDCAD","AUDCHF","AUDJPY","AUDNZD","AUDUSD","CADCHF","CADJPY","CHFJPY",
        "EURAUD","EURCAD","EURCHF","EURCZK","EURGBP","EURHKD","EURJPY","EURMXN",
        "EURNZD","EURPLN","EURRUB","EURSEK","EURTRY","EURUSD","EURZAR","GBPAUD",
        "GBPCAD","GBPCHF","GBPJPY","GBPNZD","GBPSEK","GBPUSD","NZDCAD","NZDCHF",
        "NZDJPY","NZDUSD","USDCAD","USDCHF","USDCNH","USDCZK","USDDKK","USDHKD",
        "USDJPY","USDMXN","USDNOK","USDPLN","USDRUB","USDSEK","USDSGD","USDTRY",
        "USDZAR","ANGUSD","GNFUSD","TNDUSD","DKKUSD","COPUSD","BOBUSD","MNTUSD","LAKUSD","NGNUSD","NADUSD","INRUSD",
        "UAHUSD","CRCUSD","GELUSD","KWDUSD","SEKUSD","HUFUSD","ISKUSD","AOAUSD","SZLUSD","GMDUSD","HRKUSD",
        "IQDUSD","BRLUSD","HKDUSD","BNDUSD","NIOUSD","ZARUSD","RONUSD","PYGUSD","MDLUSD","TRYUSD","MMKUSD",
        "DOPUSD","WSTUSD","IDRUSD","UYUUSD","UZSUSD","CNHUSD","CLFUSD","PHPUSD","NOKUSD","CZKUSD","JODUSD",
        "XAFUSD","RUBUSD","QARUSD","GHSUSD","LBPUSD","LSLUSD","BIFUSD","XOFUSD","MURUSD","ERNUSD","AFNUSD",
        "PENUSD","AEDUSD","TZSUSD","RWFUSD","SBDUSD","CLPUSD","MZNUSD","CADUSD","PLNUSD","ARSUSD","KHRUSD",
        "HNLUSD","GIPUSD","CHFUSD","MYRUSD","THBUSD","SCRUSD","UGXUSD","SARUSD","OMRUSD","CVEUSD","EGPUSD",
        "KZTUSD","SOSUSD","MGAUSD","KMFUSD","BHDUSD","MXNUSD","KRWUSD","BTNUSD","TTDUSD","BDTUSD","MADUSD",
        "AWGUSD","MWKUSD","CNYUSD","ILSUSD","SGDUSD","JMDUSD","BGNUSD","XDRUSD"
    ],
    miniIndex: 0,
    rate: null,
    number: 1,
    data: {
        ask: null,
        bid: null,
        state: null,
        close: null,
        open: null,
        high: null,
        low: null,
        percent: null,
    },
    language: localStorage.getItem("language") || "en",
    isMobileMenuOpened: false,
    platform: 'nttx',
};

function getAvailableCouples(availableCouples, cur, cur2) {
    if(availableCouples.includes(cur + cur2)){
        return cur + cur2
    } else {
        if(availableCouples.includes(cur2 + cur)){
            return cur2 + cur
        }
    }
    return null
}

function getInitialCouple(state,payload) {
    if(payload.cur && state.availableCouples.length){
        return getAvailableCouples(state.availableCouples, payload.cur, payload.cur2)
    }
    return state.availableCouple
}


export default (state = initialState, action) => {
    const { type, payload } = action;
    // console.error(action, "action");

    // console.log(state)

    switch (type) {
        case CHANGE_CURRENCY: return {
                ...state,
                availableCouple: payload.isInitial ? getInitialCouple(state, payload) : state.availableCouples.length ? getAvailableCouples(state.availableCouples, payload.cur, payload.cur2): state.availableCouple,
                cur: payload.cur,
                cur2: payload.cur2,
        };
        case GET_RATE_SUCCESS: return {
                ...state,
                rate: payload.rate
        };
        case GET_EXCHANGE_SUCCESS: return {
                ...state,
                data: payload.data
        };
        case OTHER_CURRENCY: return {
            ...state,
            cur: payload.cur,
            cur2: payload.cur2,
        };
        case CHANGE_NUMBER: return {
            ...state,
           number: payload.item
        };
        case LANGUAGE_CHANGE: return {
            ...state,
            language: payload.language
        };
        case NUMBER_VALUE: return{
            ...state,
            number: payload.number
        };
        case GET_AVAILABLE_COUPLES_SUCCESS: return {
            ...state,
            availableCouples: payload.availableCouples
        };
        case CLOSE_MOBILE_MENU : return {
            ...state,
            isMobileMenuOpened: false
        };
        case OPEN_MOBILE_MENU: return {
            ...state,
            isMobileMenuOpened: true
        };
        case CHANGE_PLATFORM_TYPE: return {
            ...state,
            platform: payload.platform
        };

        default: return state;

    }
}
