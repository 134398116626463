export function webs(connection) {
    if (!(this instanceof webs)) {
        return new webs(connection);
    }
    this.connection = connection;
    this.webSockets = [];
}

webs.prototype.send = function (req, fn) {
    for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
    }

    var ws = new WebSocket(this.connection);
    if(req.cmd === "exchange"){
        if(this.old){
            this.old.close()
        }
        this.old = ws;
    }


    ws.onopen = function () {
        // console.error("TTTTTTTTTTTTTTTTTTTTTTTTTTTTTT WS.OPEN ");


        var msg = JSON.stringify(req);
        ws.send(msg);
    };

    // ws.on('close', function close() {
    //     console.log('disconnected');
    // });

    ws.onmessage = function (message) {
        var data = JSON.parse(message.data);
        fn.apply(undefined, [data].concat(args));
    };
    ws.onerror = function () {
        console.log('ws error');
    };

    ws.onclose = function (e) {
        // console.log("Lcoing", e)
    }

    var cmd = req.cmd;

    if (cmd === 'ask' || cmd === "exchange") this.webSockets.push(ws);
};

webs.prototype.closeAll = function () {
    console.log(this.webSockets, "webSockets");
    this.webSockets.forEach(function (ws) {
        return ws.close();
    });
    this.webSockets = [];
};