import React, {Component} from "react";
import {webs} from "./../Socket";
import {connect} from "react-redux";
import {translate} from 'react-i18next';
import {changeCurency, changeNumber} from "../redux/actions/userActions";
import {withRouter} from "react-router-dom";

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</span>);

const renderHtml_2 = (props) => (<div
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</div>);
const number = [1, 10, 25, 50, 100, 150, 200, 250, 500, 1000, 5000, 10000, 50000];


class NewExchangeRate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auxiliary: ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR"],
            rate: "",
            invert: "",
        };
        this.scrolling = this.scrolling.bind(this);
    };


    scrolling() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    componentDidMount(data) {
        const newReq = {
            "cmd": "exchange",
            "from": this.props.cur,
            "to": this.props.cur2,
            "auxiliary": ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR"]
        };
        const web_sock = webs('wss://ifccd.net:2053/');
        web_sock.send(newReq, (data) => this.setState({auxiliary: data.auxiliary, rate: data.rate}));
    };

    render() {
        const a = this.state.auxiliary;
        const {t, cur, cur2, language} = this.props;
        const ifc_domain = `$_IFC_DOMAIN`;

        const myCur_first = `${cur}`;
        const myCur_second = `${cur2}`;
        const myCur_first_plural = `${cur}_PLURAL`;
        const myCur_second_plural = `${cur2}_PLURAL`;
        const myCur_first_dative = `${cur}_DATIVE`;
        const myCur_second_dative = `${cur2}_DATIVE`;
        const myCur_first_multiple = `${cur}_MULTIPLE`;
        const myCur_second_multiple = `${cur2}_MULTIPLE`;


        return (
            <div className="three ">
                <div className="rates_today">
                    <div className="right_rate">
                        <h2>{renderHtml(t('$_CURRENCY_STANGART', {
                            CUR1: myCur_first,
                            CUR2: myCur_second,
                            CUR1_PLURAL: (t(myCur_first_plural)),
                            CUR2_PLURAL: (t(myCur_second_plural)),
                            CUR1_DATIVE: (t(myCur_first_dative)),
                            CUR2_DATIVE: (t(myCur_second_dative)),
                            CUR1_MULTIPLE: (t(myCur_first_multiple)),
                            CUR2_MULTIPLE: (t(myCur_second_multiple)),
                            CUR1_TRANS: (t(myCur_first)),
                            CUR2_TRANS: (t(myCur_second)),
                        }))}</h2>
                        <div className="vs_cures">
                            <div className="cur1_vs_cur2">
                                <div className="some_values head_name"><span className="cur1">{cur}</span><span></span> <span className="cur2">{cur2}</span></div>
                                {
                                    number.map((item, index) => {
                                        return <div className="some_values" key={index}>
                                                    <span className="exchange-rate"
                                                         onClick={() => {
                                                             this.scrolling();
                                                             this.props.onNumberChanged(item);
                                                             this.props.history.push(`/${language}/${cur.toLowerCase()}-${cur2.toLowerCase()}/${item}`)
                                                         }
                                                         }>
                                                    {item}<span>{cur}</span>
                                                    </span>
                                                <span className="horizontal_line">=</span>
                                                <span className="cur2 __sums_block" revers="false">
                                                    <span className="__sum"
                                                          cur1="RUB"
                                                          cur2="USD"
                                                          amount="1"
                                                          title="1 Rubles to Dollars"
                                                    >
                                                        {
                                                            ((item * this.state.rate) === 0.00)
                                                                ?
                                                                "---"
                                                                :
                                                                `${((item * this.state.rate).toFixed(2))} ${cur2}`
                                                        }
                                                    </span>
                                                </span>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="cur1_vs_cur2">
                                <div>
                                    <div className="some_values head_name"><span className="cur1">{cur2}</span> <span></span><span className="cur2">{cur}</span></div>
                                {
                                    number.map((item, index) => {
                                        return <div className="some_values" key={index}>
                                                <span className="exchange-rate"
                                                     onClick={() => {
                                                         this.scrolling();
                                                         this.props.onCurrencyChanged(this.props.cur2, this.props.cur);
                                                         this.props.onNumberChanged(item)
                                                         this.props.history.push(`/${language}/${cur2.toLowerCase()}-${cur.toLowerCase()}/${item}`)
                                                     }
                                                     }>
                                                    {item}<span>{cur2}</span>
                                                </span>
                                            <span className="horizontal_line">=</span>
                                            <span className="cur2 __sums_block" revers="false">
                                                <span className="__sum"
                                                      cur1="RUB"
                                                      cur2="USD"
                                                      amount="1"
                                                      title="1 Rubles to Dollars"
                                                >
                                                    {
                                                        ((item * (1 / this.state.rate)) === Infinity) ? "---" : <div>{(item * (1 / this.state.rate)).toFixed(2)} <span>{cur}</span></div>
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cur_mini_text">
                    <div className="mini_text">
                        <span className={`flag_24 ${this.props.cur}`} title="Euro Exchange Rates"/>
                        {renderHtml_2(t(`${this.props.cur}_F_PROFILE`))}
                    </div>
                    <div className="mini_text">
                        <span className={`flag_24 ${this.props.cur2}`} title="Dollar Exchange Rates"/>
                        <div>
                            {renderHtml_2(t(`${this.props.cur2}_F_PROFILE`))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        number: state.number,
        language: state.language
    }),
    dispatch => ({
        onCurrencyChanged: (cur, cur2) => dispatch(changeCurency(cur, cur2)),
        onNumberChanged: (item) => dispatch(changeNumber(item)),
    })
)(translate('common')(withRouter(NewExchangeRate)))