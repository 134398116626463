import React from "react";
import {withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import countryList from "react-select-country-list";
import axios from "axios";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import Select from "react-select";
import './../helpers/CountryList';
import {countysList} from "../helpers/CountryList";

const PhoneInputStyle = {
   border: "1px solid red"
};


class RegisterForm extends React.Component {
    constructor(props) {

        super(props);
        this.options = countryList;

        this.state = {
            fields: {},
            errors: {},
            countryName: '',
            countryCode: '',
            flag: "",
            phone: "",
            rawPhone: "",

            options: this.options,
            value: null

        }
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);

    }

    getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            console.error("data.label", data.label)

            this.setState({
                countryName: data.country_name,
                flag: data.country,
                countryCode: data.country_calling_code,
                value: {value: data.country, label: data.country_name}
            });
        }).catch((error) => {
            console.log(error);
        });

    };

    componentDidMount(){
        console.error("STATE", this.state);
        this.getGeoInfo();
        this.setState({value: {value: this.state.flag, label: this.state.countryName}}, ()=> {console.error(this.state.value)})

    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (this.state.value.label !== prevState.label) {
    //         // this.setState({instrName: {value: null, label: "Select..."}});
    //         // alert("poxvav")
    //     }
    //
    // }

    changeHandler = value => {
    //     if(this.state.value.label == "Åland Islands"){
    //     return alert("hooop");
    // } else
        this.setState({ value, rawPhone: "", phone: "" }, ()=>{console.error(this.state.value.label, "this.state.value.label")})

    };

    handleOnChange = (value, data) => {
        this.setState({ phone: value,  rawPhone: value.replace(/[^0-9]+/g,'').slice(data.dialCode.length) })
    };

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

    }


    submituserReview() {
        const query = {
            "first_name": this.state.fields.firstName,
            "surname": this.state.fields.lastName,
            "email_address": this.state.fields.emailid,
            "password": this.state.fields.password,
            "country": this.state.countryName,
            "phone": this.state.phone
        };
        const queryParams = Object.keys(query).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
        }).join('&');
        fetch("https://privatedev.ifcmarkets.com/en/registration_demo", {
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: queryParams
        }).then((res) => {
            console.log('res = ', res);

            // alert("Review added successfully");
            this.props.attemptGetLatestReviews(this.props.sysname, this.props.countPerPage);
        })
            .catch((err)=>console.log(err));
    }

    submituserRegistrationForm(e) {
        console.warn("this.state",this.state)
        e.preventDefault();
        if (this.validateForm()) {
            let fields = {};
            fields["firstName"] = "";
            fields["lastName"] = "";
            fields["emailid"] = "";
            fields["password"] = "";
            fields["phoneNumber"] = "";
            // fields["massage"] = "";
            this.setState({fields: fields, phone: "", rawPhone:""});
            alert(`
        firstname-----------${this.state.fields.firstName}
        lastName------------${this.state.fields.lastName}
        email---------------${this.state.fields.emailid}
        password------------${this.state.fields.password}
        country-------------${this.state.countryName}
        phoneNumber---------${this.state.phone}
               `)

        }

    }

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "*Please enter your username.";
        }
        if (typeof fields["firstName"] !== "undefined") {
            if (!fields["firstName"].match(/[a-zA-Z ]*$/) || fields["firstName"].length > 20) {
                formIsValid = false;
                errors["firstName"] = "*Please enter more shortly name version.";
            }
        }
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "*Please enter massage.";
        }
        if (typeof fields["lastName"] !== "undefined") {
            if (!fields["lastName"].match(/[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["lastName"] = "*Please enter some text only.";
            }
        }
        if (!fields["emailid"]) {
            formIsValid = false;
            errors["emailid"] = "*Please enter your email-ID.";
        }
        if (typeof fields["emailid"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["emailid"])) {
                formIsValid = false;
                errors["emailid"] = "*Please enter valid email-ID.";
            }
        }
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "*Please enter massage.";
        }
        if (typeof fields["password"] !== "undefined") {
            if (!fields["password"].match(/[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["password"] = "*Please enter your password.";
            }
        }
        if (!this.state.rawPhone) {
            formIsValid = false;
            errors["phoneNumber"] = "*Please enter your phoneNumber.";
        }
        if (typeof this.state.rawPhone !== "undefined") {
            if (!(this.state.rawPhone && this.state.rawPhone.length)>0) {
                formIsValid = false;
                errors["phoneNumber"] = "*Please enter your phoneNumber.";
            }
        }
        // if(!this.state.ratingChanged){
        //     formIsValid = false;
        //     this.setState({rateMassage: ""})
        // }
        // if(formIsValid){
        //     this.setState({rateMassage: "none"});
        //     this.submituserReview()}
        // else {this.setState({rateMassage: ""})}



        if(formIsValid){
            this.submituserReview()
        }

        this.setState({
            errors: errors,
            // ratingChanged: 0
        });



        return formIsValid;


    }

    render() {
        console.error("AFTER RENDER STATE----", this.state.options)

        // const { t, language, platform } = this.props;
        return (
            <div className="content">
            <div className="open_trd_acc row">
                <h2>Want to Earn on Foreign Exchange?<br/>
                    Open Trading Account with <b>Forex Broker</b></h2>
                <div className="trd_acc_left_box">
                    <div className="trd_acc_head row">
                        <img src="/assets/img/logo.svg" alt="IFC Markets Forex Broker"/>
                        <div className="title_acc_head">
                            <span>IFC Markets</span>
                            <span>Global Regulated Forex & CFD Broker </span>
                        </div>
                    </div>
                    <div>
                        <ul className="ul-tick">
                            <li>138K Clients from 80 Countries</li>
                            <li>13 years history in Financial services</li>
                            <li>600+ Trading Instruments</li>
                            <li>Online 24/5 Support in 18 Languages</li>
                            <li>Instant Execution Low Spreads from 0.4 pip</li>
                            <li>Regulated by BVI FSC</li>
                        </ul>
                    </div>
                </div>
                <div className="trd_acc_right_box">
                    <div className="form_body">
                        <div className="form-group row">
                            <label>
                                First name <i className="required">*</i>
                            </label>
                            <div className="field">
                                <input
                                    type="text"
                                    name="firstName"
                                    value={this.state.fields.firstName}
                                    className={`${(this.state.errors.firstName)? "form-control-red":"form-control"}`}
                                    onChange={this.handleChange}
                                    required="required"/>
                                <div className="errorMsg errortext">{this.state.errors.firstName}</div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label>
                                Last name <i className="required">*</i>
                            </label>
                            <div className="field">
                                <input
                                    type="text"
                                    name="lastName"
                                    value={this.state.fields.lastName}
                                    className={`${(this.state.errors.lastName)? "form-control-red":"form-control"}`}
                                    onChange={this.handleChange}
                                    required="required"/>
                                <div className="errorMsg errortext">{this.state.errors.lastName}</div>

                            </div>
                        </div>
                        <div className="form-group row">
                            <label>
                                Email Login <i className="required">*</i>
                            </label>
                            <div className="field">
                                <input
                                    type="text"
                                    name="emailid"
                                    value={this.state.fields.emailid}
                                    onChange={this.handleChange}
                                    className={`${(this.state.errors.emailid)? "form-control-red":"form-control"}`}
                                    required="required"/>
                                <div className="errorMsg errortext">{this.state.errors.emailid}</div>
                            </div>
                        </div>
                        <div className="form-group row">
                        <label>
                            Password <i className="required">*</i>
                        </label>
                        <div className="field">
                            <input
                                type="password"
                                name="password"
                                value={this.state.fields.password}
                                onChange={this.handleChange}
                                className={`${(this.state.errors.password)? "form-control-red":"form-control"}`}
                                required="required"/>
                            <div className="errorMsg errortext">{this.state.errors.password}</div>

                        </div>
                    </div>
                        <div className="form-group row">
                        <label>
                            Country <i className="required">*</i>
                        </label>
                        <div className="field">
                            <Select
                                options={countysList}
                                value={this.state.value}
                                onChange={this.changeHandler}
                            />
                       </div>
                    </div>
                        <div className="form-group row">
                        <label>
                            PhoneNumber <i className="required">*</i>
                        </label>
                        <div className="field">
                            {
                                (this.state.errors.phoneNumber)
                                    ?
                                    <ReactPhoneInput
                                        containerStyle={PhoneInputStyle}
                                        defaultCountry={`${this.state.value && this.state.value.value.toString().toLowerCase()}`}
                                        value={this.state.phone}
                                        onChange={this.handleOnChange}
                                    />
                                    :
                                    <ReactPhoneInput
                                        defaultCountry={`${this.state.value && this.state.value.value.toString().toLowerCase()}`}
                                        value={this.state.phone}
                                        onChange={this.handleOnChange}
                                    />
                            }

                            <div className="errorMsg errortext">{this.state.errors.phoneNumber}</div>
                        </div>
                    </div>
                        <div className="form-group row " id="wants_to_subscribe_corporate">
                            <div className="field row">
                                <button onClick={this.submituserRegistrationForm}
                                        className="btn btn-md button_green"
                                >
                                    Register Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        language: state.language,
    }),
    // dispatch => ({
    //     onPlatformChange: (platform) => dispatch(onChangePlatformType(platform)),
    // })
)(withRouter(translate('common')(RegisterForm)));