import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {closeMobileMenu} from "../redux/actions/userActions";
import { connect } from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {
    MAIN_ROUTE,
    CALCULATOR_ROUTE_MAKER,
    CURRENCY_ROUTE_MAKER, MAIN_ROUTE_BY_LANGUAGE__FUNC,
} from "../helpers/constants";
const toggleClass = (el, className) => el.classList.toggle(className);


class MainLayout extends React.Component{
    
    componentDidMount() {

        document.body.dir = `${(this.props.language === 'ar' || this.props.language === 'fa') ? 'rtl' : "ltr"}` ;
    }

    render() {

        const { language } = this.props;
      const pathname = this.props.location.pathname;

        if(this.props.pathname === "/333"){
            return <div className="profile_leyout_div">
                {this.props.children}
            </div>
        } else  return (
            <div>
                <div className="lang">
                    <div className={`black_opacity ${(this.props.isMobileMenuOpened) ? 'active' : ''}`} onClick={()=>{this.props.closeMobileMenu();  toggleClass(document.body, 'bodyOverflow')}}/>
                    <div className={`mb_menu ${(this.props.isMobileMenuOpened) ? 'mb_none_active' : 'mb_active'}`}>
                        <div className="menu_header">
                            <div>Menu</div>
                            <div className="X" onClick={() => {this.props.closeMobileMenu();
                            toggleClass(document.body, 'bodyOverflow')}}/>
                        </div>
                        <div className={`menu_one ${(pathname===MAIN_ROUTE_BY_LANGUAGE__FUNC(language))?"active":""}`}>
                            <NavLink to={MAIN_ROUTE} onClick={()=>{this.props.closeMobileMenu();
                            toggleClass(document.body, 'bodyOverflow')}}>Currency Converter</NavLink>
                        </div>
                        <div className={`menu_one ${(pathname===CALCULATOR_ROUTE_MAKER(language))?"active":""}`}>
                            <NavLink to={CALCULATOR_ROUTE_MAKER(language)} onClick={()=>{this.props.closeMobileMenu();
                            toggleClass(document.body, 'bodyOverflow')}}>Trading Calculators</NavLink>
                        </div>
                        <div className={`menu_one ${(pathname===CURRENCY_ROUTE_MAKER(language))?"active":""}`}>
                            <NavLink to={CURRENCY_ROUTE_MAKER(language)} onClick={()=>{this.props.closeMobileMenu();
                            toggleClass(document.body, 'bodyOverflow')}}>Currencies</NavLink>
                        </div>
                    </div>
                    <Header />
                        <div className="profile_leyout_div content">
                            {this.props.children}
                        </div>
                    <Footer/>
                    </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        isMobileMenuOpened: state.isMobileMenuOpened,
        language: state.language,
}),
    dispatch => ({
        closeMobileMenu: () => dispatch(closeMobileMenu())
    })

)(withRouter(MainLayout));