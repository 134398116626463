import React, {Component} from "react";
import ExchangeRatetoday from "./ExchangeRatetoday";
import TopLossLeader from "./TopLossLeader";
import Chart2 from "./chart/Chart2";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {getAvailableCouples, getExchange} from "../redux/actions/userActions";
import {translate} from "react-i18next";
import GoogleAd from "./Banners/GoogleAd";
import RegisterForm from "./RegisterForm";
import NewExchangeRate from "./NewExchangeRate";

const style = {
    display: 'block',
    width: '300px',
    height: '250px',
    margin: '15px auto 20px'
};

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</span>);

const renderHtml_2 = (props) => (<div
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</div>);

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        // webs.closeAll();
        document.body.dir = `${(this.props.language === 'ar' || this.props.language === 'fa') ? 'rtl' : "ltr"}`;
        this.props.getAvailableCouples();
        this.props.getExchange(this.props.cur, this.props.cur2);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.cur !== this.props.cur) || (prevProps.cur2 !== this.props.cur2)) {
            this.props.getExchange(this.props.cur, this.props.cur2);
        }
    }

    render() {
        const {cur, cur2, availableCouple, data, number} = this.props;
        const {t} = this.props;
        const myCur_first = `${cur}`;
        const myCur_second = `${cur2}`;
        const myCur_first_plural = `${cur}_PLURAL`;
        const myCur_second_plural = `${cur2}_PLURAL`;
        const myCur_first_dative = `${cur}_DATIVE`;
        const myCur_second_dative = `${cur2}_DATIVE`;
        const myCur_first_multiple = `${cur}_MULTIPLE`;
        const myCur_second_multiple = `${cur2}_MULTIPLE`;
        const ifc_domain = `$_IFC_DOMAIN`;

        return (
            <div>
                <Helmet>
                    {/*{*/}
                    {/**/}
                        {/*(cur==="EUR" && cur2==="USD" && (this.props.language === 'en' || this.props.language === 'ru'))*/}
                            {/*?*/}
                            {/*<title>{`${(t('EURUSD',*/}
                            {/*{*/}
                                {/*CUR1: myCur_first,*/}
                                {/*CUR2: myCur_second,*/}
                                {/*CUR1_PLURAL: (t(myCur_first_plural)),*/}
                                {/*CUR2_PLURAL: (t(myCur_second_plural)),*/}
                                {/*CUR1_DATIVE: (t(myCur_first_dative)),*/}
                                {/*CUR2_DATIVE: (t(myCur_second_dative)),*/}
                                {/*CUR1_MULTIPLE: (t(myCur_first_multiple)),*/}
                                {/*CUR2_MULTIPLE: (t(myCur_second_multiple)),*/}
                                {/*CUR1_TRANS: (t(myCur_first)),*/}
                                {/*CUR2_TRANS: (t(myCur_second)),*/}
                                {/*AMOUNT: number*/}
                            {/*}*/}
                        {/*))} | CFDRATES`}*/}
                        {/*</title>*/}
                            {/*:*/}
                            <title>{`${(t('$_NEW_CURRENCY_CONVERTOR_TITLE',
                            {
                                CUR1: myCur_first,
                                CUR2: myCur_second,
                                CUR1_PLURAL: (t(myCur_first_plural)),
                                CUR2_PLURAL: (t(myCur_second_plural)),
                                CUR1_DATIVE: (t(myCur_first_dative)),
                                CUR2_DATIVE: (t(myCur_second_dative)),
                                CUR1_MULTIPLE: (t(myCur_first_multiple)),
                                CUR2_MULTIPLE: (t(myCur_second_multiple)),
                                CUR1_TRANS: (t(myCur_first)),
                                CUR2_TRANS: (t(myCur_second)),
                                AMOUNT: number
                            }
                        ))} | CFDRATES`}
                        </title>
                    {/*}*/}

                    <meta name="theme-color" content="#008f68"/>
                </Helmet>

                <div className="content">
                    {/*<RegisterForm/>*/}
                    <div className={`${availableCouple ? "my_wrapper" : ""}`}>
                        <div className="curr_cont">
                            {
                                availableCouple ?
                                    <div className="one">

                                        <div className="chart_content">
                                            <h2 className="text-center">
                                                {renderHtml(t('$_NEW_CURRENCY_STANDART_OTHER', {
                                                    CUR1: myCur_first,
                                                    CUR2: myCur_second,
                                                    CUR1_PLURAL: (t(myCur_first_plural)),
                                                    CUR2_PLURAL: (t(myCur_second_plural)),
                                                    CUR1_DATIVE: (t(myCur_first_dative)),
                                                    CUR2_DATIVE: (t(myCur_second_dative)),
                                                    CUR1_MULTIPLE: (t(myCur_first_multiple)),
                                                    CUR2_MULTIPLE: (t(myCur_second_multiple)),
                                                    CUR1_TRANS: (t(myCur_first)),
                                                    CUR2_TRANS: (t(myCur_second)),
                                                }))}
                                            </h2>
                                            <Chart2 availableCouple={availableCouple}/>
                                            <div className="see">
                                                {renderHtml(t('$_NEW_FOREX_CURRENCIES_LIVE', {
                                                    IFC_DOMAIN: (t(ifc_domain)),
                                                    CUR1_CUR2: availableCouple
                                                }))}

                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }


                            <div className={`${availableCouple ? "two" : "twos"}`}>
                                <div className="buy_sell">
                                    <h2>
                                        {renderHtml(t('$_QUOTE_PRICE_DATA_CURRENCY_CONVERTER', {
                                            CUR1: myCur_first,
                                            CUR2: myCur_second,
                                            CUR1_PLURAL: (t(myCur_first_plural)),
                                            CUR2_PLURAL: (t(myCur_second_plural)),
                                            CUR1_DATIVE: (t(myCur_first_dative)),
                                            CUR2_DATIVE: (t(myCur_second_dative)),
                                            CUR1_MULTIPLE: (t(myCur_first_multiple)),
                                            CUR2_MULTIPLE: (t(myCur_second_multiple)),
                                            CUR1_TRANS: (t(myCur_first)),
                                            CUR2_TRANS: (t(myCur_second)),
                                        }))}
                                    </h2>
                                    {
                                        availableCouple
                                            ?
                                            <div>
                                                <div className="display_of_button">
                                                    <a
                                                        href={`${(t(ifc_domain))}/open-trading-account`}
                                                        title="SELL Euro"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        <div className="buy_text b_s_p button_dark_red">
                                                            <div className="opacity_div">
                                                                {renderHtml(t('SELL'))}
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div className="cur_cur b_s_p">{cur}/{cur2}</div>
                                                    <a href={`${(t(ifc_domain))}/open-trading-account`} title="BUY Euro"
                                                       rel="noopener noreferrer"
                                                       target="_blank"
                                                    >
                                                        <div className="buy_text b_s_p button_dark_green">
                                                            <div className="opacity_div">
                                                                {renderHtml(t('BUY'))}
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div
                                                        className="bt current_instrument_bid">{data.bid || "---"}</div>
                                                    <div
                                                        className="bt current_instrument_ask">{data.ask || "---"}</div>
                                                </div>
                                                <div className="stat_changes">
                                                    <div className="pci_status_trading">
                                                            <span>
                                                                {renderHtml(t('TRADE_STATUS'))}
                                                                :&nbsp;</span>
                                                        <span className="live_closed" style={{display: "none"}}>
                                                        <span>
                                                            {renderHtml(t('LIVE_CLOSED'))}
                                                        </span>
                                                    </span>
                                                        <span className="live_trading to_up"
                                                              style={{display: "inline-block"}}>
                                                        <span>
                                                            <span/>
                                                            {renderHtml(t('LIVE_TRADING'))}
                                                        </span>
                                                    </span>
                                                    </div>
                                                    <div className="pci_change_value">
                                                        {renderHtml(t('CHANGE'))}
                                                        :&nbsp;
                                                        <span
                                                            className={`__changes to_${data.state}`}>{data.percent}%</span>
                                                    </div>
                                                    <div className="max_min">
                                                        <div className="max_min_blocks">
                                                            <div className="lbox">{renderHtml(t('PREV_CLOSING'))}</div>
                                                            <div className="rbox __close_bid">{data.close}</div>
                                                        </div>
                                                        <div className="max_min_blocks">
                                                            <div className="lbox">{renderHtml(t('OPENING'))}</div>
                                                            <div className="rbox __open_bid">{data.open}</div>
                                                        </div>
                                                        <div className="max_min_blocks">
                                                            <div className="lbox">{renderHtml(t('MAX_PRICE'))}</div>
                                                            <div className="rbox __max_bid to_up">{data.high}</div>
                                                        </div>
                                                        <div className="max_min_blocks">
                                                            <div className="lbox">{renderHtml(t('MIN_PRICE'))}</div>
                                                            <div className="rbox __min_bid to_down">{data.low}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                {
                                    availableCouple
                                        ?
                                        <div className="click_div">
                                            <a id="trading_conditions_click"
                                               href={`${(t(ifc_domain))}/trading-conditions/forex/${availableCouple}`}
                                            >
                                                {renderHtml(t('TRADING_CONDITION', {CUR1: cur, CUR2: cur2}))}
                                            </a>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>

                    </div>
                    <div className="conversion row">
                        <NewExchangeRate/>

                        <div>
                            {
                                (this.props.language !== 'fa')
                                    ?
                                    <div className="advertising">
                                        <div className="ads">
                                            <a href={`https://www.ifcmarkets.com/${this.props.language}/forex-cfd-trading?utm_source=cfdrates&utm_medium=banner_${this.props.language}`}
                                               target="_blank">
                                                <div
                                                    className={`bannerImage banner_${(this.props.language).toUpperCase()}`}/>
                                            </a>
                                        </div>
                                    </div>
                                    :
                                    <div className="advertising">
                                        <div className="ads">
                                            <a href={`https://www.ifcfx.asia/webinar-landing?utm_source=cfdrates&utm_medium=banner_webinar`}
                                               target="_blank">
                                                <div
                                                    className={`bannerImage banner_${(this.props.language).toUpperCase()}`}/>
                                            </a>
                                        </div>
                                    </div>
                            }
                            {
                                <GoogleAd
                                    client="ca-pub-7838140187159395"
                                    slot="7765769871"
                                    // format="auto"
                                    format="rectangle"
                                    wrapperDivStyle={style}
                                />
                            }
                        </div>
                    </div>
                    {renderHtml(t('MARKET_IS_DAILY',{IFC_DOMAIN: (t(ifc_domain)),}))}
                    <ExchangeRatetoday/>
                </div>





                <TopLossLeader/>

            </div>
        )
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        data: state.data,
        availableCouple: state.availableCouple,
        number: state.number,
        language: state.language

    }),
    dispatch => ({
        getExchange: (cur, cur2) => dispatch(getExchange(cur, cur2)),
        getAvailableCouples: () => dispatch(getAvailableCouples())
    })
)(translate('common')(HomePage));
