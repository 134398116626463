import React from "react";
import {translate} from "react-i18next";
import {Helmet} from "react-helmet";

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

class NotFound extends React.Component{
    render(){

        const { t } = this.props;

        return[
            <div className="body404">
                <Helmet>
                    <title>404</title>
                    <meta name="description" content="Todos on steroid!" />
                    <meta name="theme-color" content="#008f68" />
                </Helmet>
                <div className="logo_img">
                    <img id="img_desktop"
                         src="/assets/img/404_logo.png"
                         alt="IFC Markets Forex Broker"/>
                </div>
                <div className="img_404">
                    <img src="/assets/img/imogi.png"
                         alt=""/>
                </div>
                <div className="text">
                    {renderHtml(t('$_ERROR_404'))}
                </div>
            </div>,
            <style>
                {"\
                .site-header .container-fluid{\
                 max-width: 920px;\n" +
                "    width: 100%;\n" +
                "    margin: 0 auto;\n" +
                "    padding-bottom: 150px;\
                  \
                }\
              "}
            </style>
        ]
    }
}
export default (translate('common')(NotFound));